.contact-form {
  width: 100%;
  margin-top: 20px;
  overflow: visible; 
  
  ul {
    padding: 0;
    margin: 0;
    list-style: none; 
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
    gap: 15px; 
    
    li {
      padding: 0;
      margin: 0;
      opacity: 1; 
      box-sizing: border-box; 
      flex: 1 1 calc(50% - 1%); 
      min-width: 300px; 
    }

    li.half {
      flex: 1 1 calc(50% - 1%); 
      
      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 1px solid #ccc;
    background: #1e2a38;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 15px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  textarea {
    width: 100%;
    border: 1px solid #ccc;
    background: #1e2a38;
    height: auto;
    font-size: 16px;
    color: #fff;
    padding: 15px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 4px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    letter-spacing: 1px; /* Reduced spacing for readability */
    text-decoration: none;
    padding: 10px 15px;
    border: 1px solid #ffd700;
    border-radius: 4px;
    background: transparent;
    text-transform: uppercase;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    transition: background 0.3s, color 0.3s;
    
    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}

.map-wrap {
  background: rgba(30, 30, 30, 0.8); 
  float: right;
  width: 100%; 
  max-width: 600px; 
  height: 60vh;
  margin-top: 100px; 
  padding: 15px; 
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); 
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

img {
  width: 70%; 
  height: auto;
  border-radius: 10px; 
}

@media (max-width: 1000px) {
  img {
    display: none; /* Hide image on screens smaller than 768px */
  }

  .map-wrap {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
}

