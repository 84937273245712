.about-page {
  padding-left: 100px;
  padding-right: 50px;
  width: 90%;
  height: 100vh; 
  overflow-y: auto; 
  overflow-x: hidden; 
  position: relative;

  .btn {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 23px;
    height: 40px;
    line-height: 34px;
    border: 2px solid #ffd700;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
  }

  .btn:hover {
    transform: translateY(-3px);
    background: #ffd700;
  }

  .stage-cube-cont {
    width: 50%;
    height: 50%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
  }

/* Programming Languages Section */
.programming-languages-section {
  margin-top: 50px;
  text-align: center;

  h2 {
    font-size: 24px;
    color: #ffd700;
    margin-bottom: 20px;
  }

  .language-icons {
    display: flex;
    justify-content: center;
    gap: 30px; /* Space between icons */
    flex-wrap: wrap;

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2rem;
      margin: 15px;

      span {
        margin-top: 10px;
        font-size: 1rem;
        color: #333;
      }
    }
  }
}

.desc-text {
  font-family: 'Poppins', sans-serif; 
  font-weight: 600; 
  color: #ffffff; 
  font-size: 2rem;  
}

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }

    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }

    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }

    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }

    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }

    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }

  @keyframes spincube {

    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }

    33% {
      transform: rotateY(-90deg) rotateX(90deg);
    }

    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
      transform: rotateX(90deg);
    }
  }

  @media screen and (max-width: 1200px) {
    .stage-cube-cont {
      position: initial;
      width: 100%;
      height: 100%;
      overflow: visible;
    }


  }
}