.projects-page {
    padding-left: 100px;
    padding-right: 50px;
    width: 90%;
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    h1 {
        margin-left: 100px;
        margin-top: 100px;
        color: #fff;
        font-size: 56px;
        line-height: 53px;
        margin-left: 100px;
        margin-top: 100px;
        font-family: 'Coolvetica';
        font-weight: 400;
    }


    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .projects-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.2) 0,
                    rgba(0, 0, 0, 1));
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            width: 80%;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
            width: 80%;
        }

        .p {
            font-size: 150px;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }

    @media screen and (max-width: 1000px) {
        h1.page-title {
            font-size: 45px;
        }
    
        .image-box {
            position: relative;
            flex: 1 1 100%; 
            height: auto; 
            overflow: hidden;
            border-radius: 10px;
            max-width: 100%; 
        }
    
        .image-box img {
            width: 100%; 
            height: auto; 
            display: block; 
        }
    
        .image-box .title {
            font-size: 17px;
        }
    
        .image-box .description {
            font-size: 11px;
            font-weight: 500;
        }
    }
}