.wrong-page {


  h1 {
    color: #fff;
    font-size: 60px;
    line-height: 70px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;


    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }


    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #a59b9b;
    margin-top: 20px;
    font-weight: 400;
    font-size: 15px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }

  img {

    animation: rotateIn 1s linear both;
    animation-delay: 1.4s;
  }

}

.text-zone {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  max-height: 90%;
}

.section-right {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 50%;
  height: 115%;
  margin-top: -3%;
  text-align: center;
}


@media screen and (max-width: 1200px) {
  .tags {
    // .home-page h1:before,
    // .home-page h1:after {
    display: none;
  }

  .home-page .text-zone {
    position: initial;
    width: 100%;
    transform: none;
    padding: 10px;
    box-sizing: border-box;
  }

  .home-page .flat-button {
    float: none;
    display: block;
    margin: 20px auto 0 auto;
    width: 124px;
  }

  .section-right {
    float: none;
    margin: 0;
    width: 100%;
    height: 60%;
  }

  h1.page-title {
    font-size: 8px;
  }





}